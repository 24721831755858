import React from 'react';
import { connect } from 'react-redux';
import { Margin } from 'styled-components-spacing';
import { Heading } from 'components';
import { InnerText } from './HowToFill.styled';

const HowToFill = () => {
  return (
    <Margin bottom="24">
      <Margin bottom="8">
        <Heading size="h4">How to fill the report</Heading>
      </Margin>
      <InnerText>
        Dear MA representative,
        <br /> <br />
        The report should be filled in with reliable data that your MA has approved and in an
        accurate manner. The report will be used for IPMA internal use only. The data will be the
        basis for invoicing the annual membership fee (based on the number of members you uploaded),
        and it will measure the activity and evolution of your MA over time. It will also help to
        calculate the number of votes you will have at the Council of Delegates meeting.
        <br />
        Number of corporate partners are the number of organisations MAs have agreements with.
        <br /> <br />
        The report should be filled by <b>February 7, 2025.</b>
        <br /> <br />
        In case the MA fails to report its figures by 7th February, it is assumed that the number of
        individual members has increased by 10% and the invoice will be impacted accordingly.
        <br /> <br />
        Thank you for your contribution.
      </InnerText>
    </Margin>
  );
};

HowToFill.defaultProps = {
  errors: {},
};

HowToFill.propTypes = {};

export default connect(null, null)(HowToFill);

import {
  SET_LIST,
  SET_LIST_PAGE,
  SET_LIST_PAGES,
  SET_LIST_PER_PAGE,
  SET_LIST_SORT,
  SET_LIST_FILTERS,
  REMOVE_LIST_FILTERS,
  SET_LIST_VIEW,
  REMOVE_LIST_VIEW,
} from '../constants';

const initialFilters = {
  courses: {
    uuid: '',
    name: '',
    location: '',
    dateFrom: '',
    dateTo: '',
    courseType: '',
    modeOfDelivery: '',
    perspectiveCompetencies: [],
    practiceCompetencies: [],
    peopleCompetencies: [],
  },
  glossary: {
    term: '',
    definition: '',
  },
  events: {
    name: '',
    location: '',
    dateFrom: '',
    dateTo: '',
    modeOfDelivery: '',
    language: '',
    tags: [],
  },
  ma: {
    name: '',
    location: '',
  },
  yc: {
    name: '',
    location: '',
  },
  organisations: {
    name: '',
    location: '',
  },
  projects: {
    name: '',
    location: '',
  },
};

const initialState = {
  courses: {
    items: [],
    filters: initialFilters.courses,
    page: 1,
    pages: 1,
    perPage: 10,
    sort: 'createdDate.desc',
    view: {},
  },
  glossary: {
    items: [],
    filters: initialFilters.glossary,
    page: 1,
    pages: 1,
    perPage: 10,
    sort: 'id.asc',
    view: {},
  },
  events: {
    items: [],
    filters: initialFilters.events,
    page: 1,
    pages: 1,
    perPage: 10,
    sort: 'dateFrom.desc',
    view: {},
  },
  ma: {
    items: [],
    filters: initialFilters.ma,
    page: 1,
    pages: 1,
    perPage: 10,
    sort: 'createdAt.desc',
    view: {},
  },
  yc: {
    items: [],
    filters: initialFilters.yc,
    page: 1,
    pages: 1,
    perPage: 10,
    sort: 'createdAt.desc',
    view: {},
  },
  organisations: {
    items: [],
    filters: initialFilters.organisations,
    page: 1,
    pages: 1,
    perPage: 10,
    sort: 'createdDate.desc',
    view: {},
  },
  projects: {
    items: [],
    filters: initialFilters.projects,
    page: 1,
    pages: 1,
    perPage: 10,
    sort: 'dateFrom.desc',
    view: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LIST:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          items: action.payload,
        },
      };
    case SET_LIST_PAGES:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          pages: action.payload,
        },
      };
    case SET_LIST_PAGE:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          page: action.payload,
        },
      };
    case SET_LIST_PER_PAGE:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          perPage: action.payload,
        },
      };
    case SET_LIST_SORT:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          sort: action.payload,
        },
      };
    case SET_LIST_FILTERS:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          filters: {
            ...state[action.listType].filters,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case REMOVE_LIST_FILTERS:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          filters: initialFilters[action.listType],
        },
      };
    case SET_LIST_VIEW:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          view: action.payload,
        },
      };
    case REMOVE_LIST_VIEW:
      return {
        ...state,
        [action.listType]: {
          ...state[action.listType],
          view: {},
        },
      };
    default:
      return state;
  }
};

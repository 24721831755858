import User from 'containers/user/User';
import Dashboard from 'containers/user/Dashboard/Dashboard';
import Profile from 'containers/user/Profile/Profile';
import ProfileEdit from 'containers/user/ProfileEdit/ProfileEdit';
import PasswordEdit from 'containers/user/PasswordEdit/PasswordEdit';
import DeleteProfile from 'containers/user/DeleteProfile/DeleteProfile';
import Courses from 'containers/user/Courses/Courses';
import CoursesCompleted from 'containers/user/Courses/Completed/Completed';
import CoursesUpcoming from 'containers/user/Courses/Upcoming/Upcoming';
import CoursesPending from 'containers/user/Courses/Pending/Pending';
import Certification from 'containers/user/Certification/Certification';
import CreateOrganisation from 'containers/user/CreateOrganisation/CreateOrganisation';
import MyMa from 'containers/user/MyMa/MyMa';
import MyYc from 'containers/user/MyYc/MyYc';
import CertificationAdd from 'containers/user/CertificationAdd/CertificationAdd';
import MyBadges from 'containers/user/MyBadges/MyBadges';
import Cis from 'containers/user/Cis/Cis';
import MyCPD from 'containers/user/MyCPD/MyCPD';
import MyLocalLibrary from 'containers/user/MyLocalLibrary/MyLocalLibrary';
import Glossary from 'containers/user/MyLocalLibrary/Glossary/Glossary';
import UnderConstruction from '../shared/UnderConstruction/UnderConstruction';
import MyLibrary from '../shared/MyLibrary/MyLibrary';
import { routes } from './routes';

const USER = 'userBase';
const DASHBOARD = 'userDashboard';
const PROFILE = 'userProfile';
const PROFILE_EDIT = 'userProfileEdit';
const PASSWORD_EDIT = 'userPasswordEdit';
const PROFILE_DELETE = 'userProfileDelete';
const COURSES = 'userCourses';
const COURSES_COMPLETED = 'userCoursesCompleted';
const COURSES_UPCOMING = 'userCoursesUpcoming';
const COURSES_PENDING = 'userCoursesPending';
const CERTIFICATION = 'userCertification';
const CERTIFICATION_ADD = 'userCertificationAdd';
const ORGANISATION_CREATE = 'userOrganisationCreate';
const MY_MA = 'userMyMA';
const MY_YC = 'userMyYC';
const MY_BADGES = 'userMyBadges';
const CIS = 'ssoCis';
const MY_CPD = 'userCPD';
const MY_LIBRARY = 'userMyLibrary';
const MY_LIBRARY_GLOSSARY = 'userMyLibraryGlossary';
const REG_SHOP = 'userRegShop';

export default [
  {
    key: USER,
    path: '/user',
    component: User,
    exact: false,
    withAuth: true,
    permissions: {
      appType: 'user',
    },
    routes: [
      {
        key: DASHBOARD,
        path: '/user',
        component: Dashboard,
        exact: true,
        withAuth: false,
      },
      {
        key: PROFILE,
        path: '/user/profile',
        component: Profile,
        exact: true,
        withAuth: false,
      },
      {
        key: PROFILE_EDIT,
        path: '/user/profile/edit',
        component: ProfileEdit,
        exact: true,
        withAuth: false,
      },
      {
        key: PASSWORD_EDIT,
        path: '/user/profile/password',
        component: PasswordEdit,
        exact: true,
        withAuth: false,
      },
      {
        key: PROFILE_DELETE,
        path: '/user/profile/delete',
        component: DeleteProfile,
        exact: true,
        withAuth: false,
      },
      {
        key: ORGANISATION_CREATE,
        path: '/user/organisations/create',
        component: CreateOrganisation,
        exact: true,
        withAuth: false,
      },
      {
        key: CERTIFICATION,
        path: '/user/certification',
        component: Certification,
        exact: true,
        withAuth: false,
      },
      {
        key: CERTIFICATION_ADD,
        path: '/user/certification/add',
        component: CertificationAdd,
        exact: true,
        withAuth: false,
      },
      {
        key: MY_MA,
        path: '/user/my-ma',
        component: MyMa,
        exact: true,
        withAuth: false,
        permissions: {
          appType: 'ma',
        },
      },
      {
        key: MY_YC,
        path: '/user/my-yc',
        component: MyYc,
        exact: true,
        withAuth: false,
        permissions: {
          appType: 'yc',
        },
      },
      {
        key: MY_BADGES,
        path: '/user/my-badges',
        component: MyBadges,
        exact: true,
        withAuth: false,
      },
      {
        key: COURSES,
        path: '/user/courses',
        component: Courses,
        exact: false,
        withAuth: false,
        routes: [
          {
            key: COURSES_COMPLETED,
            path: '/user/courses/completed',
            component: CoursesCompleted,
            exact: false,
            withAuth: false,
          },
          {
            key: COURSES_UPCOMING,
            path: '/user/courses/upcoming',
            component: CoursesUpcoming,
            exact: false,
            withAuth: false,
          },
          {
            key: COURSES_PENDING,
            path: '/user/courses/pending',
            component: CoursesPending,
            exact: false,
            withAuth: false,
          },
        ],
      },
      {
        key: CIS,
        path: '/user/cis',
        component: Cis,
        exact: false,
        withAuth: false,
      },
      {
        key: MY_CPD,
        path: '/user/my-cpd',
        component: MyCPD,
        exact: true,
        withAuth: false,
      },
      {
        key: MY_LIBRARY,
        path: '/user/my-books',
        component: MyLocalLibrary,
        exact: true,
        withAuth: false,
      },
      {
        key: MY_LIBRARY_GLOSSARY,
        path: '/user/my-books/glossary',
        component: Glossary,
        exact: true,
        withAuth: false,
      },
      {
        key: REG_SHOP,
        path: '/user/reg-shop',
        component: UnderConstruction,
        exact: true,
        withAuth: false,
      },
    ],
  },
];

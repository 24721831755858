import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Margin } from 'styled-components-spacing';
import {
  Alerts,
  Alert,
  Col,
  Container,
  Field,
  Header,
  Row,
  PageContent,
  Button,
  Spinner,
  Heading,
} from 'components';
import {
  sendMaReportCreator,
  setMaReportErrorsCreator,
  loadMaLastReportCreator,
} from 'store/actionsCreators';
import { checkPermissions, isLoading, globalPropTypes } from 'helpers';
import clearErrors from 'helpers/clearErrors';
import editReducer, { defaultState, yearFieldsKeys, updateFieldCreator } from './reducer';
import HowToFill from '../HowToFill/HowToFill';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.ma.reports',
    url: '/ma/reports',
  },
  {
    title: 'navigation.breadcrumbs.ma.addReport',
    url: '',
  },
];

const AddReport = ({
  loading,
  // lastReport,
  // loadLastReport,
  sendReport,
  userPermissions,
  errors,
  removeError,
  history,
}) => {
  const { t } = useTranslation();
  const [report, dispatch] = useReducer(editReducer, defaultState);

  // useEffect(() => {
  //   loadLastReport();
  // }, []);

  // useEffect(() => {
  //   if (lastReport) {
  //     dispatch(setEditDataCreator(lastReport));
  //   }
  // }, [lastReport]);

  const submit = () => {
    const params = new FormData();
    Object.keys(report).forEach((category) => {
      Object.keys(report[category]).forEach((field) => {
        if (!field.toLowerCase().includes('notes')) {
          params.append(
            `${yearFieldsKeys[0]}[${field}]`,
            report[category][field][yearFieldsKeys[0]],
          );
          params.append(
            `${yearFieldsKeys[1]}[${field}]`,
            report[category][field][yearFieldsKeys[1]],
          );
          // params.append(
          //   `${yearFieldsKeys[2]}[${field}]`,
          //   report[category][field][yearFieldsKeys[2]],
          // );
        } else {
          params.append(`${field}`, report[category][field].notes);
        }
      });
    });

    sendReport(params, history);
  };

  const selectYear = (label) => {
    switch (label) {
      case 'previousYearReport':
        return new Date().getFullYear() - 1;
      case 'actualYearReport':
        return new Date().getFullYear();
      case 'forecastYearReport':
        return new Date().getFullYear() + 1;
      default:
        return null;
    }
  };

  const handleInputChange = (group, field, inner, errorName) => (e) => {
    dispatch(
      updateFieldCreator({
        name: group,
        value: {
          ...report[group],
          [field]: { ...report[group][field], [inner]: e.target.value },
        },
      }),
    );
    if (errors[errorName]) removeError(clearErrors(errors, errorName));
  };

  const renderInput = (category, field, forYear) => (
    <Margin bottom="20" as={Col} size={{ lg: 1 / 4 }}>
      <Field
        label={t(`page.ma.addReport.${forYear}`, { year: selectYear(forYear) })}
        error={errors[`${forYear}[${field}]`]}
      >
        <input
          id={`${category}${field}${forYear}`}
          type="text"
          placeholder={t('page.ma.addReport.placeholder')}
          value={report[category][field][forYear]}
          onChange={handleInputChange(category, field, forYear)}
        />
      </Field>
    </Margin>
  );

  const renderTextArea = (category, field) => (
    <Margin bottom="20" as={Col} size={{ lg: 3 / 4 }}>
      <Field label={t('page.ma.addReport.additionalNotes')} error={errors[field]}>
        <textarea
          id={`${category}${field}`}
          placeholder={t('page.ma.addReport.placeholderTextArea')}
          value={report[category][field].notes}
          onChange={handleInputChange(category, field, 'notes')}
        />
      </Field>
    </Margin>
  );

  const renderRow = (category) => {
    return (
      <>
        {Object.keys(report[category]).map((field) => (
          <Margin bottom="16">
            {Object.keys(report[category][field]).length !== 1 ? (
              <>
                <Margin bottom="8">
                  <Heading size="h5">{t(`page.ma.addReport.${field}`)}</Heading>
                </Margin>
                <Row>
                  {Object.keys(report[category][field]).map((forYear) =>
                    renderInput(category, field, forYear),
                  )}
                </Row>
                <Margin bottom="2">
                  	{t(`page.ma.addReport.notes.${field}`) !== `page.ma.addReport.notes.${field}` && t(`page.ma.addReport.notes.${field}`).split("<br />").map(line => <div>{line}</div>)}
                </Margin>
                
              </>
            ) : (
              <Row>{renderTextArea(category, field)}</Row>
            )}
          </Margin>
        ))}
      </>
    );
  };

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.ma.addReport.title')} />
      <Container>
        <PageContent>
          {checkPermissions(
            {
              roles: ['ma_send_members_report'],
            },
            userPermissions,
          ) ? (
            <>
              {loading ? <Spinner /> : null}
              {!loading ? (
                <Col>
                  <Alerts type="ma" />
                  <HowToFill />
                  {Object.keys(report).map((category) => (
                    <Margin bottom="36">
                      <Margin bottom="24">
                        <Heading size="h4">{t(`page.ma.addReport.${category}`)}</Heading>
                      </Margin>
                      {renderRow(category)}
                    </Margin>
                  ))}

                  <Row>
                    <Col size={{ lg: 1 / 4 }}>
                      <Margin top={{ xs: 12 }}>
                        <Button type="button" onClick={submit}>
                          {t('page.ma.addReport.send')}
                        </Button>
                      </Margin>
                    </Col>
                  </Row>
                </Col>
              ) : null}
            </>
          ) : (
            <Alert type="info">{t('page.forbidden.subtitle')}</Alert>
          )}
        </PageContent>
      </Container>
    </>
  );
};

AddReport.defaultProps = {
  errors: {},
};

AddReport.propTypes = {
  loading: PropTypes.bool.isRequired,
  // lastReport: PropTypes.shape({}).isRequired,
  sendReport: PropTypes.func.isRequired,
  // loadLastReport: PropTypes.func.isRequired,
  userPermissions: globalPropTypes.UserPermissions.isRequired,
  errors: PropTypes.shape({
    // 'previousYearReport[individualMembersF]': PropTypes.string,
    // 'previousYearReport[individualMembersM]': PropTypes.string,
    // 'previousYearReport[individualMembersStudentsF]': PropTypes.string,
    // 'previousYearReport[individualMembersStudentsM]': PropTypes.string,
    // 'previousYearReport[yCMembersF]': PropTypes.string,
    // 'previousYearReport[yCMembersM]': PropTypes.string,
    // 'previousYearReport[corporateMembers]': PropTypes.string,
    // 'previousYearReport[inTotal]': PropTypes.string,
    // 'previousYearReport[eventsNumber]': PropTypes.string,
    // 'previousYearReport[eventsTotalParticipants]': PropTypes.string,
    // 'previousYearReport[trainingsProvided]': PropTypes.string,
    // 'previousYearReport[regPartners]': PropTypes.string,
    // 'previousYearReport[financialResult]': PropTypes.string,
    'actualYearReport[individualMembers]': PropTypes.string,
    'actualYearReport[individualMembersF]': PropTypes.string,
    'actualYearReport[individualMembersM]': PropTypes.string,
    'actualYearReport[nationalYoungCrewMembers]': PropTypes.string,
    'actualYearReport[students]': PropTypes.string,
    'actualYearReport[corporateMembers]': PropTypes.string,
    'actualYearReport[activeVolunteers]': PropTypes.string,
    'actualYearReport[eventsNumber]': PropTypes.string,
    'actualYearReport[eventsTotalParticipants]': PropTypes.string,
    'actualYearReport[trainingsProvided]': PropTypes.string,
    'actualYearReport[regPartners]': PropTypes.string,
    // 'actualYearReport[financialResult]': PropTypes.string,
    'forecastYearReport[individualMembers]': PropTypes.string,
    'forecastYearReport[individualMembersF]': PropTypes.string,
    'forecastYearReport[individualMembersM]': PropTypes.string,
    'forecastYearReport[nationalYoungCrewMembers]': PropTypes.string,
    'forecastYearReport[students]': PropTypes.string,
    'forecastYearReport[corporateMembers]': PropTypes.string,
    'forecastYearReport[activeVolunteers]': PropTypes.string,
    'forecastYearReport[eventsNumber]': PropTypes.string,
    'forecastYearReport[eventsTotalParticipants]': PropTypes.string,
    'forecastYearReport[trainingsProvided]': PropTypes.string,
    'forecastYearReport[regPartners]': PropTypes.string,
    // 'forecastYearReport[financialResult]': PropTypes.string,
    membershipNotes: PropTypes.string,
    eventsNotes: PropTypes.string,
    educationNotes: PropTypes.string,
    // financialNotes: PropTypes.string,
  }),
  removeError: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ ma, user, loading }) => ({
  lastReport: ma.report,
  loading: isLoading(loading, 'MA_REPORT'),
  userPermissions: user.userPermissions,
  errors: ma.reportErrors,
});

const mapDispatchToProps = (dispatch) => ({
  sendReport: (payload, history) => dispatch(sendMaReportCreator(payload, history)),
  removeError: (payload) => dispatch(setMaReportErrorsCreator(payload)),
  loadLastReport: () => dispatch(loadMaLastReportCreator({ editLatestReport: false })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReport);

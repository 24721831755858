import React from 'react';
import { Header } from 'components';
import { useTranslation } from 'react-i18next';
import { ConMsg } from './UnderConstruction.styled';

const UnderConstruction = ({breadcrumbs}) => {
  const { t } = useTranslation();

  return (
    <>
      <Header breadcrumbs={breadcrumbs} title={t('page.user.regShop.title')} />
      <ConMsg>
        <span>{t('global.underConstruction')}</span>
      </ConMsg>
    </>
  );
};

export default UnderConstruction;

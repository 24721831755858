import { all, takeLatest, call, put, select, debounce, cancelled } from 'redux-saga/effects';

import * as maService from 'api/services/ma';
import {
  LOAD_MA_PEOPLE_BAR,
  LOAD_MA_PEOPLE_USERS,
  CHANGE_MA_PEOPLE_USERS_ROLE,
  DELETE_MA_PEOPLE_USER,
  LOAD_MA_PEOPLE_LIST,
  ACCEPT_MA_PEOPLE_LIST_USER,
  DECLINE_MA_PEOPLE_LIST_USER,
  LOAD_MA_PEOPLE_ADD_LIST_USERS,
  ADD_MA_PEOPLE_USERS,
  LOAD_MA_PROFILE,
  UPDATE_MA_PROFILE,
  CREATE_EVENT,
  LOAD_MA_DASHBOARD,
  LOAD_MA_EVENTS_BAR,
  LOAD_MA_EVENTS,
  APPLY_USER_TO_MA,
  LOAD_MA_PROFILE_UPCOMING_EVENTS,
  CANCEL_MA_EVENT,
  REOPEN_MA_EVENT,
  LOAD_MA_EVENT,
  EDIT_MA_EVENT,
  LOAD_MA_PEOPLE_ORGANISATIONS,
  REMOVE_MA_PEOPLE_LIST_USER,
  LOAD_MA_COURSES_BAR,
  LOAD_MA_COURSES,
  LOAD_MA_ASSESSORS,
  CHANGE_MA_COURSES_ASSESSOR,
  CANCEL_MA_COURSE,
  ACCEPT_MA_COURSE,
  LOAD_MA_PEOPLE_MEMBERS,
  REMOVE_MA_PEOPLE_MEMBERS_USER,
  DUPLICATE_MA_EVENT,
  SEND_MA_REPORT,
  DOWNLOAD_MA_PEOPLE_REPORT,
  LOAD_MA_REPORT_LIST,
  LOAD_MA_REPORT,
  LOAD_MA_LAST_REPORT,
  CIS_REDIRECT,
  LOAD_MA_PEOPLE_YC,
  EXPORT_REPORT,
  UPDATE_REPORT,
} from 'store/constants';
import {
  setNewLoadingCreator,
  removeLoadingCreator,
  setMaPeopleBarCreator,
  setMaPeopleUsersCreator,
  loadMaPeopleUsersCreator,
  setNewAlertCreator,
  setMaPeopleListCreator,
  loadMaPeopleListCreator,
  loadMaPeopleBarCreator,
  setMaPeopleAddListUsersCreator,
  setMaProfileCreator,
  loadMaProfileCreator,
  setUpdateMaProfileErrorsCreator,
  setMaProfileUsersRoleCreator,
  setMaDashboardCreator,
  setMaEventsBarCreator,
  loadMaEventsBarCreator,
  loadMaProfileUcomingEventsCreator,
  setMaEventsCreator,
  setMaEventsStatusCreator,
  loadMaEventsCreator,
  setMaPeopleOrganisationsCreator,
  setMaProfileUcomingEventsCreator,
  setMaCoursesBarCreator,
  setMaCoursesCreator,
  setMaCoursesPagesCreator,
  setMaAssessorsCreator,
  loadMaCoursesCreator,
  loadMaCoursesBarCreator,
  setMaPeopleMembersCreator,
  loadMaPeopleMembersCreator,
  setMaReportsListCreator,
  setMaReportCreator,
  setMaPeopleYcCreator,
  setMaUpdateReportCreator,
} from 'store/actionsCreators';

import { get, map } from 'lodash';
import { parseBoxesList, parseParams, parseUserList, parseReport } from 'helpers';
import i18next from 'i18next';

function* loadBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(maService.getBar);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(
        setMaPeopleBarCreator({
          users: bar.maUserList ? bar.maUserList : 0,
          organisations: bar.organisationList ? bar.organisationList : 0,
          list: bar.people ? bar.people : 0,
          members: bar.maMembers ? bar.maMembers : 0,
          youngcrew: bar.ycMembers ? bar.ycMembers : 0,
        }),
      );
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ma people bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadUsers() {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const { page, perPage, sort, filters } = yield select(({ ma }) => ma.people.users);
    const response = yield call(maService.getUsers, {
      page,
      maxResult: perPage,
      'sort-by': sort ? sort.join('.') : '',
      ...filters,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadMaPeopleBarCreator({ withoutLoader: true }));
      yield put(
        setMaPeopleUsersCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[GET] ma people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* changeUserRole({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const response = yield call(maService.changeUserRole, payload);
    if (response.ok) {
      yield put(loadMaPeopleUsersCreator());
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'ma',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[GET] ma people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* removeUser({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const response = yield call(maService.removeUser, payload);
    if (response.ok) {
      yield put(loadMaPeopleUsersCreator());
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'ma',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[GET] ma people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* loadList() {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const { page, perPage, sort, filters } = yield select(({ ma }) => ma.people.list);
    const response = yield call(maService.getList, {
      page,
      maxResult: perPage,
      'sort-by': sort ? sort.join('.') : '',
      ...filters,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadMaPeopleBarCreator({ withoutLoader: true }));
      yield put(
        setMaPeopleListCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
          localMemberAssociation: result.localMemberAssociation,
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[GET] ma people list error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* loadMembers() {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const { page, perPage, sort, filters } = yield select(({ ma }) => ma.people.members);
    const response = yield call(maService.getMembers, {
      page,
      maxResult: perPage,
      'sort-by': sort ? sort.join('.') : '',
      ...filters,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadMaPeopleBarCreator({ withoutLoader: true }));
      yield put(
        setMaPeopleMembersCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
          localMemberAssociation: result.localMemberAssociation,
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[GET] ma members list error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* acceptListUser({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const response = yield call(maService.acceptListUser, payload);
    if (response.ok) {
      yield put(loadMaPeopleListCreator());
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'ma',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[POST] accept ma list user error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* declineListUser({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const response = yield call(maService.declineListUser, payload);
    if (response.ok) {
      yield put(loadMaPeopleListCreator());
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'ma',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[POST] decline ma list user error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* removeListUser({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const response = yield call(maService.removeListUser, payload);
    if (response.ok) {
      yield put(loadMaPeopleListCreator());
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'ma',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[POST] remove ma list user error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* removeMembersUser({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const response = yield call(maService.removeListUser, payload);
    if (response.ok) {
      yield put(loadMaPeopleMembersCreator());
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors.length) {
        yield put(
          setNewAlertCreator({
            type: 'ma',
            status: 'error',
            list: errors,
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[POST] remove ma members user error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* loadAddListUsers({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const response = yield call(maService.getAddListUsers, payload);
    if (response.ok) {
      const {
        results: { result: users },
      } = yield response.json();
      yield put(setMaPeopleAddListUsersCreator(users));
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[GET] ma people add list users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* addUsers({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(maService.addUsers, payload);
    if (response.ok) {
      yield put(setMaPeopleAddListUsersCreator([]));
      history.push('/ma/people/');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      if (errors) {
        yield put(loadMaPeopleListCreator());
        yield put(
          setNewAlertCreator({
            type: 'ma',
            status: 'error',
            text: i18next.t('global.alert.error'),
          }),
        );
      }
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ma people add list users error: ', error);
  }
}

function* loadProfile() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(maService.getProfile);
    if (response.ok) {
      const {
        results: {
          result: { ma: profile, userRoleOfMa: usersRole },
        },
      } = yield response.json();

      yield all([
        put(
          setMaProfileCreator({
            ...profile,
            country: {
              label: profile.country ? profile.country.name : '',
              value: profile.country ? profile.country.id : '',
            },
            address: {
              ...profile.address,
              country: {
                label: profile.address.country ? profile.address.country.name : '',
                value: profile.address.country ? profile.address.country.id : '',
              },
            },
            cbAddress: {
              ...profile.cbAddress,
              country: profile.cbAddress
                ? {
                    label: profile.cbAddress.country ? profile.cbAddress.country.name : '',
                    value: profile.cbAddress.country ? profile.cbAddress.country.id : '',
                  }
                : { label: '', value: '' },
            },
            ycAddress: {
              ...profile.ycAddress,
              country: profile.ycAddress
                ? {
                    label: profile.ycAddress.country ? profile.ycAddress.country.name : '',
                    value: profile.ycAddress.country ? profile.ycAddress.country.id : '',
                  }
                : { label: '', value: '' },
            },
          }),
        ),
        put(setMaProfileUsersRoleCreator(parseUserList(usersRole))),
        put(loadMaProfileUcomingEventsCreator()),
      ]);
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ma profile error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* updateProfile({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(maService.updateProfile, payload);
    if (response.ok) {
      yield put(loadMaProfileCreator());
      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.update'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      yield put(
        setUpdateMaProfileErrorsCreator(
          errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
        ),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] update ma profile error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* createEvent({ payload, history, setErrors }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(maService.createEvent, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
      history.push('/ma/my-events');
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] create event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadDashboard() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(maService.getDashboard);
    if (response.ok) {
      const {
        results: { result: data },
      } = yield response.json();

      const dashboard = {
        name: get(data, 'ma.name') || '',
        phone: get(data, 'ma.telephone') || '',
        email: get(data, 'ma.email') || '',
        website: get(data, 'ma.website') || '',
        photo: get(data, 'ma.photo') || '',
        members: String(get(data, 'members') || 0),
        chartPeople: get(data, 'chartPeople') || [],
        chartCertifiedMembers: get(data, 'chartCertificate') || [],
        chartEventsAndTraining: get(data, 'chartEventsTrainings') || [],
      };

      yield put(setMaDashboardCreator(dashboard));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ma dashboard error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadEventsBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(maService.getEventsBar);
    if (response.ok) {
      const {
        results: { result: bar },
      } = yield response.json();
      yield put(setMaEventsBarCreator({ ...bar }));
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ma events bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadEvents({ payload }) {
  try {
    if (payload && payload.status) yield put(setMaEventsStatusCreator(payload.status));
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const { page, perPage, status } = yield select(({ ma }) => ma.events.list);
    const response = yield call(maService.getEvents, {
      page,
      maxResult: perPage,
      status,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadMaEventsBarCreator({ withoutLoader: true }));
      yield put(
        setMaEventsCreator({
          items: parseBoxesList('events', result.items),
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[GET] ma people users error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* cancelEvent({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const response = yield call(maService.cancelEvent, payload);
    if (response.ok) {
      yield put(loadMaEventsCreator());
      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA' }));
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] ma cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* reopenEvent({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const response = yield call(maService.reopenEvent, payload);
    if (response.ok) {
      yield put(loadMaEventsCreator());
      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA' }));
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] ma cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* loadEvent({ payload, setEvent }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(maService.getEvent, payload);
    if (response.ok) {
      const {
        results: { result: event },
      } = yield response.json();
      yield setEvent({
        ...event,
        dateFrom: event.dateFrom ? new Date(event.dateFrom) : null,
        dateTo: event.dateTo ? new Date(event.dateTo) : null,
        mainLanguage: event.mainLanguage
          ? { label: event.mainLanguage.name, value: event.mainLanguage.id }
          : null,
        secondaryLanguage: event.secondaryLanguage
          ? {
              label: event.secondaryLanguage.name,
              value: event.secondaryLanguage.id,
            }
          : null,
        timezone: event.timezone ? { label: event.timezone.gmt, value: event.timezone.id } : null,
        currency: event.currency ? { label: event.currency.name, value: event.currency.id } : null,
        modeOfDelivery: event.modeOfDelivery ? event.modeOfDelivery.id : null,
        address: {
          ...event.address,
          country: event.address.country
            ? { label: event.address.country.name, value: event.address.country.id }
            : null,
        },
        tags: event.tags.map((item) => item.id),
        imageFile: event.photo || '',
        cpdRoles:
          event.cpdRoleEventCourses && event.cpdRoleEventCourses.length > 0
            ? event.cpdRoleEventCourses.map((el) => ({
                role: el.cpdRole,
                points: el.points,
              }))
            : [
                {
                  role: null,
                  points: null,
                },
              ],
      });
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] get ma event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* editEvent({ payload, id, history, setErrors }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(maService.editEvent, payload, id);
    if (response.ok) {
      yield put(loadMaEventsCreator());
      history.push('/ma/my-events');
      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();
      setErrors(
        errors.reduce((prev, current) => ({ ...prev, [current.path]: current.message }), {}),
      );
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[GET] ma cancel event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* duplicateEvent({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const body = new FormData();
    body.append('eventId', payload);

    const response = yield call(maService.duplicateEvent, body);
    if (response.ok) {
      yield put(loadMaEventsCreator());
      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA' }));
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );
    console.log('[POST] ma duplicate event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* applyUserToMa({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const { id } = yield select(({ user }) => user.user);

    const response = yield call(
      maService.applyUserToMa,
      JSON.stringify({ user: +id, ma: +payload.maId }),
    );

    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('ma.applyUserToMaSuccess'),
        }),
      );
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();

      yield all(
        errors.map((item) =>
          put(
            setNewAlertCreator({
              type: 'ma',
              status: 'error',
              text: item,
            }),
          ),
        ),
      );
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    console.log('[POST] apply user to ma error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadOrganisations() {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const { page, perPage, sort } = yield select(({ ma }) => ma.people.organisations);
    const response = yield call(maService.getOrganisations, {
      page,
      maxResult: perPage,
      'sort-by': sort ? sort.join('.') : '',
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadMaPeopleBarCreator({ withoutLoader: true }));
      yield put(
        setMaPeopleOrganisationsCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[GET] ma people organisations error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* loadMaProfileUpcomingEvents() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(maService.getProfileUpcomingEvents);
    if (response.ok) {
      const {
        results: { result: items },
      } = yield response.json();

      const events = parseBoxesList('events', items);

      yield put(setMaProfileUcomingEventsCreator(events));
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ma profile upcoming events error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadCoursesBar({ payload }) {
  try {
    if (payload && !payload.withoutLoader) yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const { memberAssociation } = yield select(({ user }) => user.user);

    const response = yield call(maService.getCoursesBar, memberAssociation.id);
    if (response.ok) {
      const {
        results: {
          result: { entity: bar },
        },
      } = yield response.json();
      yield put(
        setMaCoursesBarCreator({
          unassigned: bar.unassigned || 0,
          assigned: bar.assigned || 0,
          accepted: bar.accepted || 0,
          canceled: bar.canceled || 0,
        }),
      );
    }
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    if (payload && !payload.withoutLoader) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ma courses bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function loadCourses(view) {
  switch (view.listType) {
    case 'unassigned':
      return loadCoursesList('unassigned', 'LOAD_MA_COURSES_UNASSIGNED');
    case 'assigned':
      return loadCoursesList('assigned', 'LOAD_MA_COURSES_ASSIGNED');
    case 'accepted':
      return loadCoursesList('accepted', 'LOAD_MA_COURSES_ACCEPTED');
    case 'canceled':
      return loadCoursesList('canceled', 'LOAD_MA_COURSES_CANCELED');
    default:
      return null;
  }
}

function* loadCoursesList(type, loading, apiName = type) {
  try {
    yield put(setNewLoadingCreator({ type: loading }));

    const [page, perPage] = yield select(({ ma }) => [
      ma.courses[type].page,
      ma.courses[type].perPage,
    ]);
    const { memberAssociation } = yield select(({ user }) => user.user);

    const params = parseParams({
      maxResults: perPage,
      page,
    });

    const response = yield call(maService.getCourses, memberAssociation.id, apiName, params);
    if (response.ok) {
      const {
        results: {
          result: {
            entity: { items, pageCount },
          },
        },
      } = yield response.json();

      const data = parseBoxesList('courses', items);

      yield put(setMaCoursesCreator(type, data));
      yield put(setMaCoursesPagesCreator(type, pageCount || 1));
      yield put(loadMaCoursesBarCreator({ withoutLoader: true }));
    }

    yield put(removeLoadingCreator({ type: loading }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: loading }));
    console.log(`[GET] ma courses ${type} error: `, error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: loading }));
  }
}

function* loadAssessors() {
  const [{ memberAssociation }, assessorsList] = yield select(({ user, ma }) => [
    user.user,
    ma.assessors,
  ]);

  if (assessorsList.length) return;

  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(maService.getAssessors, memberAssociation.id);
    if (response.ok) {
      const {
        results: {
          result: { entity: data },
        },
      } = yield response.json();

      const assessors = map(data, (n) => ({
        label: [get(n, 'user.firstName') || '', get(n, 'user.lastName') || '']
          .filter((el) => !!el)
          .join(' '),
        value: get(n, 'user.id'),
      }));

      yield put(setMaAssessorsCreator(assessors));
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] ma assessors bar error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* changeAssessor({ listType, payload: { courseId, userId, prevUserId } }) {
  const LOADING = `LOAD_MA_COURSES_${listType.toUpperCase()}`;
  try {
    yield put(setNewLoadingCreator({ type: LOADING }));

    if (prevUserId) yield call(maService.changeAssessor, 'unassign', courseId, prevUserId);

    const response = yield call(maService.changeAssessor, 'assign', courseId, userId);
    if (response.ok) {
      yield put(loadMaCoursesCreator(listType));

      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }

    yield put(removeLoadingCreator({ type: LOADING }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: LOADING }));

    yield put(loadMaCoursesCreator(listType));

    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );

    console.log('[GET] ma change assessor error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: LOADING }));
    }
  }
}

function* acceptCourse({ listType, payload: { id } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(maService.acceptCourse, id);
    if (response.ok) {
      yield put(loadMaCoursesCreator(listType));

      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );

    console.log('[GET] ma accept course error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* cancelCourse({ listType, payload: { id } }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(maService.cancelCourse, id);

    if (response.ok) {
      yield put(loadMaCoursesCreator(listType));

      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }

    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );

    console.log('[GET] ma cancel course error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* sendReport({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA_REPORT' }));
    const response = yield call(maService.sendReport, payload);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }

    history.push('/ma/reports');
    yield put(removeLoadingCreator({ type: 'MA_REPORT' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();

      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'error',
          text: errors.message,
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA_REPORT' }));

    console.log('[POST] send report error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA_REPORT' }));
    }
  }
}

function* downloadMaPeopleReport({ payload }) {
  try {
    let apiToCall = maService.downlodMaPeopleReport;
    let xlsxTitle = 'people-report';
    switch (payload) {
      case 'users':
        xlsxTitle = 'users-report';
        apiToCall = maService.downlodMaUsersReport;
        break;
      case 'organisations':
        xlsxTitle = 'organisations-report';
        apiToCall = maService.downlodMaOrganisationsReport;
        break;
      case 'people':
        xlsxTitle = 'people-report';
        apiToCall = maService.downlodMaPeopleReport;
        break;
      case 'members':
        xlsxTitle = 'members-report';
        apiToCall = maService.downlodMaMembersReport;
        break;
      case 'youngCrew':
        xlsxTitle = 'ma-youngcrew-report';
        apiToCall = maService.downlodMaYoungCrewReport;
        break;
      default:
        break;
    }

    const response = yield call(apiToCall);
    if (response.ok) {
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${xlsxTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  } catch (error) {
    yield put(
      setNewAlertCreator({
        type: 'ma',
        status: 'error',
        text: i18next.t('global.alert.error'),
      }),
    );

    console.log('[GET] ma people report error: ', error);
  }
}

function* loadMaReportsList() {
  try {
    yield put(setNewLoadingCreator({ type: 'MA_REPORT' }));

    const response = yield call(maService.getMaReportsList);
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(setMaReportsListCreator(result));
    }

    yield put(removeLoadingCreator({ type: 'MA_REPORT' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA_REPORT' }));
    console.log('[GET] ma reports list error: ');
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'MA_REPORT' }));
  }
}

function* loadReport({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(maService.getMaReport, payload.id);
    if (response.ok) {
      const {
        results: { result: report },
      } = yield response.json();
      const parsedReport = parseReport(report, !payload.editReport, payload.editReport);
      if (payload.editReport) {
        yield put(setMaUpdateReportCreator({ report: parsedReport, name: report.version }));
      } else {
        yield put(setMaReportCreator({ report: parsedReport, name: report.version }));
      }
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] get ma event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* loadLastReport({ payload }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    const response = yield call(maService.getMaLastReport);
    if (response.ok) {
      const {
        results: { result: report },
      } = yield response.json();
      const parsedReport = parseReport(report, false, payload.editLatestReport);
      yield put(setMaReportCreator({ report: parsedReport, name: report.version }));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    console.log('[GET] get ma event error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    }
  }
}

function* updateReport({ payload, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'MA_REPORT' }));
    const response = yield call(maService.updateReport, payload.id, payload.body);
    if (response.ok) {
      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'success',
          text: i18next.t('global.alert.success'),
        }),
      );
    }
    history.push('/ma/reports');
    yield put(removeLoadingCreator({ type: 'MA_REPORT' }));
  } catch (error) {
    if (error.response) {
      const { errors } = yield error.response.json();

      yield put(
        setNewAlertCreator({
          type: 'ma',
          status: 'error',
          list: errors.flatMap(innerList => innerList.map(error => error.message))
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA_REPORT' }));

    console.log('[POST] update latest report error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA_REPORT' }));
    }
  }
}

function* cisRedirect() {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));
    yield put(setNewLoadingCreator({ type: 'CIS_REDIRECT' }));
    const response = yield call(maService.cisRedirect);
    if (response.ok) {
      const {
        results: { result: url },
      } = yield response.json();
      window.location.href = url;
    }
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
    yield put(removeLoadingCreator({ type: 'CIS_REDIRECT' }));
    console.log('[GET] cis redirect error: ', error);
  }
}

function* loadPeopleYC() {
  try {
    yield put(setNewLoadingCreator({ type: 'MA' }));
    const { page, perPage, sort, filters } = yield select(({ ma }) => ma.people.yc);
    const response = yield call(maService.getPeopleYcList, {
      page,
      maxResult: perPage,
      'sort-by': sort ? sort.join('.') : '',
      ...filters,
    });
    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      yield put(loadMaPeopleBarCreator({ withoutLoader: true }));
      yield put(
        setMaPeopleYcCreator({
          items: result.items,
          pages: Math.ceil(result.totalItems / perPage),
          localMemberAssociation: result.localMemberAssociation,
        }),
      );
    }
    yield put(removeLoadingCreator({ type: 'MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'MA' }));
    console.log('[GET] ma people organisations error: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: 'MA' }));
    }
  }
}

function* downloadReport({ payload }) {
  const loadingType = payload.report ? 'IPMA_REPORT' : 'GLOBAL';
  try {
    yield put(setNewLoadingCreator({ type: loadingType }));
    const response = yield call(maService.exportReport, payload.report ? payload.report.id : null);
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      const blob = yield response.blob();
      const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
      const link = document.createElement('a');
      const filename = payload.report
        ? `${payload.report.memberAssociation.name}_report_${payload.report.version}`.replace(
            /\.| /g,
            '_',
          )
        : `group_report_${Date.now()}`;
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    yield put(removeLoadingCreator({ type: loadingType }));
  } catch (error) {
    if (error.response) {
      const {
        results: { result },
      } = yield error.response.json();
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'error',
          text: result.charAt(0).toUpperCase() + result.slice(1),
        }),
      );
    } else {
      yield put(
        setNewAlertCreator({
          type: 'ipma',
          status: 'error',
          text: i18next.t('global.alert.error'),
        }),
      );
    }
    yield put(removeLoadingCreator({ type: loadingType }));
    console.log('[GET] error while exporting report / reports: ', error);
  } finally {
    if (yield cancelled()) {
      yield put(removeLoadingCreator({ type: loadingType }));
    }
  }
}

export default function* watchMa() {
  yield takeLatest(LOAD_MA_PEOPLE_BAR, loadBar);
  yield takeLatest(LOAD_MA_PEOPLE_USERS, loadUsers);
  yield takeLatest(CHANGE_MA_PEOPLE_USERS_ROLE, changeUserRole);
  yield takeLatest(DELETE_MA_PEOPLE_USER, removeUser);
  yield takeLatest(LOAD_MA_PEOPLE_LIST, loadList);
  yield takeLatest(ACCEPT_MA_PEOPLE_LIST_USER, acceptListUser);
  yield takeLatest(DECLINE_MA_PEOPLE_LIST_USER, declineListUser);
  yield takeLatest(REMOVE_MA_PEOPLE_LIST_USER, removeListUser);
  yield takeLatest(LOAD_MA_PEOPLE_MEMBERS, loadMembers);
  yield takeLatest(REMOVE_MA_PEOPLE_MEMBERS_USER, removeMembersUser);
  yield debounce(1000, LOAD_MA_PEOPLE_ADD_LIST_USERS, loadAddListUsers);
  yield takeLatest(ADD_MA_PEOPLE_USERS, addUsers);
  yield takeLatest(LOAD_MA_PROFILE, loadProfile);
  yield takeLatest(UPDATE_MA_PROFILE, updateProfile);
  yield takeLatest(CREATE_EVENT, createEvent);
  yield takeLatest(LOAD_MA_DASHBOARD, loadDashboard);
  yield takeLatest(LOAD_MA_EVENTS_BAR, loadEventsBar);
  yield takeLatest(LOAD_MA_EVENTS, loadEvents);
  yield takeLatest(CANCEL_MA_EVENT, cancelEvent);
  yield takeLatest(REOPEN_MA_EVENT, reopenEvent);
  yield takeLatest(LOAD_MA_EVENT, loadEvent);
  yield takeLatest(EDIT_MA_EVENT, editEvent);
  yield takeLatest(DUPLICATE_MA_EVENT, duplicateEvent);
  yield takeLatest(APPLY_USER_TO_MA, applyUserToMa);
  yield takeLatest(LOAD_MA_PEOPLE_ORGANISATIONS, loadOrganisations);
  yield takeLatest(LOAD_MA_PROFILE_UPCOMING_EVENTS, loadMaProfileUpcomingEvents);
  yield takeLatest(LOAD_MA_COURSES_BAR, loadCoursesBar);
  yield takeLatest(LOAD_MA_COURSES, loadCourses);
  yield takeLatest(LOAD_MA_ASSESSORS, loadAssessors);
  yield takeLatest(CHANGE_MA_COURSES_ASSESSOR, changeAssessor);
  yield takeLatest(ACCEPT_MA_COURSE, acceptCourse);
  yield takeLatest(CANCEL_MA_COURSE, cancelCourse);
  yield takeLatest(SEND_MA_REPORT, sendReport);
  yield takeLatest(DOWNLOAD_MA_PEOPLE_REPORT, downloadMaPeopleReport);
  yield takeLatest(LOAD_MA_REPORT_LIST, loadMaReportsList);
  yield takeLatest(LOAD_MA_REPORT, loadReport);
  yield takeLatest(LOAD_MA_LAST_REPORT, loadLastReport);
  yield takeLatest(CIS_REDIRECT, cisRedirect);
  yield takeLatest(LOAD_MA_PEOPLE_YC, loadPeopleYC);
  yield takeLatest(EXPORT_REPORT, downloadReport);
  yield takeLatest(UPDATE_REPORT, updateReport);
}

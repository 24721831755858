const copyObject = (obj) => JSON.parse(JSON.stringify(obj));

export default (report, toRead, editReport = false) => {
  const currentYear = new Date().getFullYear();
  const yearFields = {
    // previousYearReport: '',
    actualYearReport: '',
    forecastYearReport: '',
  };
  const target = {
    membership: {
      individualMembers: copyObject(yearFields),
      individualMembersF: copyObject(yearFields),
      individualMembersM: copyObject(yearFields),
      nationalYoungCrewMembers: copyObject(yearFields),
      students: copyObject(yearFields),
      corporateMembers: copyObject(yearFields),
      activeVolunteers: copyObject(yearFields),
    },
    events: {
      eventsNumber: copyObject(yearFields),
      eventsTotalParticipants: copyObject(yearFields),
      eventsNotes: { notes: '' },
    },
    education: {
      trainingsProvided: copyObject(yearFields),
      regPartners: copyObject(yearFields),
      educationNotes: { notes: '' },
    },
    // financialHealth: {
    //   financialResult: {
    //     previousYearReport: '',
    //     actualYearReport: '',
    //     forecastYearReport: '',
    //   },
    //   financialNotes: { notes: '' },
    // },
  };

  let parsed = {};

  if (toRead) {
    Object.keys(target).forEach((category) => {
      Object.keys(target[category]).forEach((field) => {
        if (!field.includes('Notes')) {
          Object.keys(yearFields).forEach((year) => {
            target[category][field][year] = report[year][field];
          });
        } else {
          target[category][field] = report[field];
        }
      });
    });

    parsed = {
      items: {
        ...target,
      },
      // previousYear: report.previousYear,
      actualYear: report.actualYear,
      forecastYear: report.forecastYear,
      version: report.version,
      maName: report.memberAssociation ? report.memberAssociation.name : '',
      kamNotes: report.kamNotes,
    };
  } else {
    Object.keys(target).forEach((category) => {
      Object.keys(target[category]).forEach((field) => {
        if (editReport) {
          if (!field.includes('Notes')) {
            Object.keys(yearFields).forEach((year) => {
              target[category][field][year] = report[year][field] ? report[year][field] : '';
            });
          } else {
            target[category][field].notes = report[field] ? report[field] : '';
          }
        } else if (!field.includes('Notes')) {
          if (currentYear === report.actualYear) {
            // target[category][field].previousYearReport = report.previousYearReport[field]
            //   ? report.previousYearReport[field]
            //   : '';
            target[category][field].actualYearReport = report.actualYearReport[field]
              ? report.actualYearReport[field]
              : '';
          } else {
            // target[category][field].previousYearReport = report.actualYearReport[field]
            //   ? report.actualYearReport[field]
            //   : '';
          }
        }
      });
    });
    parsed = {
      ...target,
    };
  }

  return parsed;
};

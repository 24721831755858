import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  PageContent,
  Container,
  Header,
  Row,
  Col,
  Filters,
  Table,
  Dropdown,
  Heading,
  FiltersControls,
  Pagination,
  Spinner,
} from 'components';
import { connect } from 'react-redux';
import { Padding, Margin } from 'styled-components-spacing';
import {
  loadListCreator,
  setListPageCreator,
  setListPerPageCreator,
  setListSortCreator,
  setListFiltersCreator,
  removeListFiltersCreator
} from 'store/actionsCreators';
import { isLoading, globalPropTypes } from 'helpers';
import { useTranslation } from 'react-i18next';

const breadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.glossary',
    url: '/user/my-books/glossary',
  },
];

const Glossaries = ({
  loading,
  list,
  loadList,
  setPage,
  setPerPage,
  setSort,
  setFilters,
  removeFilters,
}) => {
  const { filters, items, page, pages, perPage, sort } = list;
  const { t } = useTranslation();
  const scrollAfterFiltersRef = useRef(null);

  useEffect(() => {
    setPage(1);
    loadList();
  }, [perPage, sort]);

  useEffect(() => {
    setSort('id.asc');
  }, []);

  const filtersFields = [
    {
      type: 'input',
      name: 'term',
      id: 'f-term',
      label: t('page.glossary.termLabel'),
      placeholder: t('page.glossary.termPlaceholder'),
    },
    {
      type: 'input',
      name: 'definition',
      id: 'f-definition',
      label: t('page.glossary.definitionLabel'),
      placeholder: t('page.glossary.definitionPlaceholder'),
    }
  ];

  return (
    <>
      <Header
        breadcrumbs={breadcrumbs}
        title={t('page.glossary.title')}
      />
      <PageContent>
        <Container>
          <Row>
            <Col size={{ xl: 1 / 4 }}>
              <Padding all={{ xs: 12, xl: 20 }} className="border-xs">
                <Dropdown
                  openbreakpoint="xl"
                  title={
                    <Heading as="h3" size="h4" noLine>
                      {t('page.glossary.filtersTitle')}
                    </Heading>
                  }
                >
                  <Filters
                    fields={filtersFields}
                    filters={filters}
                    onChangeFilter={(payload) => setFilters(payload)}
                    onClickApplyFilters={() => {
                      setPage(1);
                      loadList();
                    }}
                    onClickRemoveAllFilters={() => removeFilters()}
                    scrollAfterFiltersRef={scrollAfterFiltersRef}
                  />
                </Dropdown>
              </Padding>
            </Col>
            <Margin as={Col} size={{ xl: 3 / 4 }} top={{ xs: 16, xl: 0 }}>
              <FiltersControls
                perPageSelected={perPage}
                perPageOnChange={(payload) => setPerPage(payload.value)}
                sortBySelected={sort}
                sortByOnChange={(payload) => setSort(payload.value)}
                sortByConfig={{
                  name: 'sortBy',
                  options: [
                    { label: 'Term: descending', value: 'term.desc' },
                    { label: 'Term: ascending', value: 'term.asc' },
                    { label: 'Definition: descending', value: 'definition.desc' },
                    { label: 'Definition: ascending', value: 'definition.asc' },
                  ],
                }}
              />
              {loading ? <Spinner /> : null}
              {!loading ? (
                <div ref={scrollAfterFiltersRef}>
                  <Table tablebreakpoint="lg">
                  <Table.Head>
                    <Table.Row>
                      <Table.Heading>{t('page.glossary.termLabel')}</Table.Heading>
                      <Table.Heading>{t('page.glossary.definitionLabel')}</Table.Heading>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {loading ? (
                      <Table.Row>
                        <Table.Cell colSpan={2}>
                          <Spinner />
                        </Table.Cell>
                      </Table.Row>
                    ) : null}
                    {!loading && !items.length ? (
                      <Table.Row>
                        <Table.Cell colSpan={2}>{t('global.noResultsFound')}</Table.Cell>
                      </Table.Row>
                    ) : null}
                    {!loading && items.length
                      ? items.map((glossary) => (
                          <Table.Row key={glossary.id}>
                            <Table.Cell>
                              {glossary.term}
                            </Table.Cell>
                            <Table.Cell>
                              {glossary.definition}
                            </Table.Cell>
                          </Table.Row>
                        ))
                      : null}
                  </Table.Body>
                </Table>
                  <Pagination
                    styled
                    currentPage={page}
                    totalPages={pages}
                    onChange={(payload) => {
                      setPage(payload);
                      loadList();
                    }}
                  />
                </div>
              ) : null}
            </Margin>
          </Row>
        </Container>
      </PageContent>
    </>
  );
};

Glossaries.propTypes = {
  loading: PropTypes.bool,
  list: globalPropTypes.ListPropsTypes.isRequired,
  loadList: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  removeFilters: PropTypes.func.isRequired,
};

Glossaries.defaultProps = {
  perspectiveCompetencies: [],
  peopleCompetencies: [],
  practiceCompetencies: [],
  courseTypes: [],
  deliveryModes: [],
  loading: false,
};

const mapStateToProps = ({ loading, list: { glossary: list } }) => ({
  loading: isLoading(loading, 'LOAD_LIST_GLOSSARIES'),
  list,
});

const mapDispatchToProps = (dispatch) => ({
  loadList: () => dispatch(loadListCreator('glossary')),
  setPage: (payload) => dispatch(setListPageCreator('glossary', payload)),
  setPerPage: (payload) => dispatch(setListPerPageCreator('glossary', payload)),
  setSort: (payload) => dispatch(setListSortCreator('glossary', payload)),
  setFilters: (payload) => dispatch(setListFiltersCreator('glossary', payload)),
  removeFilters: () => dispatch(removeListFiltersCreator('glossary')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Glossaries);
